/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        //  var t=50;
        // jQuery(document).scroll(function(){
        //   var i=jQuery(window).scrollTop();
        //   i>=t?jQuery("#topheader").addClass("fixed"):jQuery("#topheader").removeClass("fixed")});


         $( ".more-btn" ).click(function() {
            $( ".more-text" ).toggle();
            $(".more-btn").hide();
          });

         $(document).on('scroll', function() {          
              console.log( $(this).scrollTop() );
              if($(this).scrollTop()>=80){ 
                  console.log('1');
                  $("header.banner").addClass("fixed-banner");
              }else{
                  $("header.banner").removeClass("fixed-banner");
                  console.log('2'); 
              }
        });
         $('.zip-ajax-search').submit(function(event)
          {
            event.preventDefault(); // if you want to disable the action
            submitZip();
            return false;

          });


         function submitZip(){          
          console.log('heyyyy');
          var t=jQuery( $('.zip-ajax-search') ).serialize();
          console.log(t);
          jQuery.ajax({
            type:"POST",
            url:"/wp-admin/admin-ajax.php",
            data:t,
            success:function(t){
              window.location.href=""===t?e+"404.php":t}
            })
        }


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
